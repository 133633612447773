<template>
  <div>
    <Loading v-if="loading" />
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Özet
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="summary"
        :items="sales.summary"
      >
        <template #cell(accessories)="data">
          {{ data.item.accessories | toCurrency }} TL
        </template>
        <template #cell(accessory_unit)="data">
          {{ data.item.accessory_unit | toCurrency }} TL
        </template>
        <template #cell(accessory_percent)="data">
          % {{ data.item.accessory_percent | toNumber }}
          <div class="text-warning">
            <small>{{ data.item.accessory_count | toNumber }} Adet</small>
          </div>
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.sales }}
            </b-th>
            <b-th class="text-right">
              {{ sales.summaryTotal.accessories | toCurrency }} TL
            </b-th>
            <b-th class="text-right">
              {{ sales.summaryTotal.accessory_unit | toCurrency }} TL
            </b-th>
            <b-th class="text-right">
              % {{ sales.summaryTotal.accessory_percent | toCurrency }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Temsilciler
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="users"
        :items="sales.users"
      >
        <template #cell(accessories)="data">
          {{ data.item.accessories | toCurrency }} TL
        </template>
        <template #cell(accessory_unit)="data">
          {{ data.item.accessory_unit | toCurrency }} TL
        </template>
        <template #cell(accessory_percent)="data">
          % {{ data.item.accessory_percent | toNumber }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th colspan="2">
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.sales }}
            </b-th>
            <b-th class="text-right">
              {{ sales.usersTotal.accessories | toCurrency }} TL
            </b-th>
            <b-th class="text-right">
              {{ sales.usersTotal.accessory_unit | toCurrency }} TL
            </b-th>
            <b-th class="text-right">
              % {{ sales.usersTotal.accessory_percent | toCurrency }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Havuz
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="pools"
        :items="sales.pools"
      >
        <template #cell(accessories)="data">
          {{ data.item.accessories | toCurrency }} TL
        </template>
        <template #cell(accessory_unit)="data">
          {{ data.item.accessory_unit | toCurrency }} TL
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.sales }}
            </b-th>
            <b-th class="text-right">
              {{ sales.poolsTotal.accessories | toCurrency }} TL
            </b-th>
            <b-th class="text-right">
              {{ sales.poolsTotal.accessory_unit | toCurrency }} TL
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
  </div>
</template>
<script>
import {
  BTable, BTh, BTr,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'

export default {
  name: 'Accessories',
  components: {
    BTr,
    BTh,
    BTable,
    Loading,
  },
  data() {
    return {
      loading: null,
      summary: [
        {
          key: 'brand', label: 'Marka', sortable: true,
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'accessories', label: 'Aksesuar Satışı', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
        {
          key: 'accessory_unit', label: 'Araç Başı Aksesuar', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
        {
          key: 'accessory_percent', label: 'Aksesuarlı Araç Oranı', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
      ],
      users: [
        {
          key: 'username', label: 'Danışman', sortable: true, thClass: 'width-250',
        },
        {
          key: 'brand', label: 'Marka', sortable: true, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'accessories', label: 'Aksesuar Satışı', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
        {
          key: 'accessory_unit', label: 'Araç Başı Aksesuar', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
        {
          key: 'accessory_percent', label: 'Aksesuarlı Araç Oranı', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
      ],
      pools: [
        {
          key: 'brand', label: 'Marka', sortable: true,
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'accessories', label: 'Aksesuar Satışı', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
        {
          key: 'accessory_unit', label: 'Araç Başı Aksesuar', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    sales() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = true
      const data = this.$store.getters['saleUsers/getSales']
      if (data.users.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
  },
}
</script>
