<template>
  <div>
    <Loading v-if="loading" />
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Perakende Özet
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="summary"
        :items="sales.summary"
      >
        <template #cell(trafik_percent)="data">
          %{{ data.item.trafik_percent }}
        </template>
        <template #cell(kasko_percent)="data">
          %{{ data.item.kasko_percent }}
        </template>
        <template #cell(marka_kasko_percent)="data">
          %{{ data.item.marka_kasko_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.trafik }}
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.kasko }}
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.marka_kasko }}
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.hedef_disi }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryTotal.trafik_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryTotal.kasko_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryTotal.marka_kasko_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Temsilciler
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="users"
        :items="sales.users"
      >
        <template #cell(trafik_percent)="data">
          %{{ data.item.trafik_percent }}
        </template>
        <template #cell(kasko_percent)="data">
          %{{ data.item.kasko_percent }}
        </template>
        <template #cell(marka_kasko_percent)="data">
          %{{ data.item.marka_kasko_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th colspan="2">
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.trafik }}
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.kasko }}
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.marka_kasko }}
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.hedef_disi }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.usersTotal.trafik_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.usersTotal.kasko_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.usersTotal.marka_kasko_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Havuz
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="pools"
        :items="sales.pools"
      >
        <template #cell(trafik_percent)="data">
          %{{ data.item.trafik_percent }}
        </template>
        <template #cell(kasko_percent)="data">
          %{{ data.item.kasko_percent }}
        </template>
        <template #cell(marka_kasko_percent)="data">
          %{{ data.item.marka_kasko_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.trafik }}
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.kasko }}
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.marka_kasko }}
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.hedef_disi }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.poolsTotal.trafik_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.poolsTotal.kasko_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.poolsTotal.marka_kasko_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
  </div>
</template>
<script>
import {
  BTable, BTh, BTr,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'

export default {
  name: 'Insurances',
  components: {
    BTable,
    BTr,
    BTh,
    Loading,
  },
  data() {
    return {
      loading: null,
      summary: [
        {
          key: 'brand', label: 'Marka', sortable: true, thClass: 'align-middle',
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'trafik', label: 'Trafik', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'kasko', label: 'Kasko', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'marka_kasko', label: 'Marka Kasko', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'hedef_disi', label: 'Hedef Dışı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'trafik_percent', label: 'Trafik Oranı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'kasko_percent', label: 'Kasko Oranı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'marka_kasko_percent', label: 'Marka Kasko Oranı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
      ],
      users: [
        {
          key: 'username', label: 'Danışman', sortable: true, thClass: 'align-middle',
        },
        {
          key: 'brand', label: 'Marka', sortable: true, thClass: 'align-middle',
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'trafik', label: 'Trafik', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'kasko', label: 'Kasko', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'marka_kasko', label: 'Marka Kasko', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'hedef_disi', label: 'Hedef Dışı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'trafik_percent', label: 'Trafik Oranı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'kasko_percent', label: 'Kasko Oranı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'marka_kasko_percent', label: 'Marka Kasko Oranı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
      ],
      pools: [
        {
          key: 'brand', label: 'Marka', sortable: true, thClass: 'align-middle',
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'trafik', label: 'Trafik', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'kasko', label: 'Kasko', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'marka_kasko', label: 'Marka Kasko', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'hedef_disi', label: 'Hedef Dışı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'trafik_percent', label: 'Trafik Oranı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'kasko_percent', label: 'Kasko Oranı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
        {
          key: 'marka_kasko_percent', label: 'Marka Kasko Oranı', sortable: true, thClass: 'align-middle text-center width-100', tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    sales() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = true
      const data = this.$store.getters['saleUsers/getSales']
      if (data.users.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
  },
}
</script>
