<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <b-col>
              <v-select
                v-model="id_com_brand"
                label="title"
                :options="brands"
                placeholder="Marka Seçiniz"
                :reduce="brand => brand.id"
              />
            </b-col>
            <b-col>
              <b-form-datepicker
                v-model="sdate"
                v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-col>
            <b-col>
              <b-form-datepicker
                v-model="edate"
                v-bind="{labelNoDateSelected: 'Bitiş Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="primary"
                :disabled="sdate == null || edate == null"
                @click="getSales"
              >
                <FeatherIcon icon="FilterIcon" />
                Görüntüle
              </b-button>
            </b-col>
            <b-col cols="auto">
              <b-dropdown
                :disabled="sales.users.length === 0"
                text="Dışa Aktar"
                icon="DownloadIcon"
                variant="danger"
              >
                <b-dropdown-item
                  :href="downloadPDF"
                  target="_blank"
                >
                  PDF İndir
                </b-dropdown-item>
                <b-dropdown-item
                  :href="downloadExcel"
                  target="_blank"
                >
                  Excel İndir
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-card-header>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col cols="12">
        <Loading />
      </b-col>
    </b-row>
    <b-row v-if="sales.users.length > 0">
      <b-col cols="12">
        <b-card>
          <b-tabs
            pills
            fill
          >
            <b-tab active>
              <template #title>
                <feather-icon icon="PieChartIcon" />
                <span>Satış</span>
              </template>
              <Sales />
            </b-tab>
            <!--            <b-tab>-->
            <!--              <template #title>-->
            <!--                <feather-icon icon="PackageIcon" />-->
            <!--                <span>Aksesuar</span>-->
            <!--              </template>-->
            <!--              <Accessories />-->
            <!--            </b-tab>-->
            <b-tab>
              <template #title>
                <feather-icon icon="UmbrellaIcon" />
                <span>Sigorta</span>
              </template>
              <Insurances />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="RepeatIcon" />
                <span>Takas</span>
              </template>
              <Swaps />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="MessageCircleIcon" />
                <span>Görüşme</span>
              </template>
              <Interviews />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BFormDatepicker, BRow, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'
// eslint-disable-next-line import/extensions
import Sales from '@/views/Reports/Sales/SaleUsers/Sales'
// eslint-disable-next-line import/extensions
import Accessories from '@/views/Reports/Sales/SaleUsers/Accessories'
// eslint-disable-next-line import/extensions
import Insurances from '@/views/Reports/Sales/SaleUsers/Insurances'
// eslint-disable-next-line import/extensions
import Swaps from '@/views/Reports/Sales/SaleUsers/Swaps'
// eslint-disable-next-line import/extensions
import Interviews from '@/views/Reports/Sales/SaleUsers/Interviews'

export default {
  name: 'SaleUsers',
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    vSelect,
    Loading,
    Sales,
    Accessories,
    Insurances,
    Swaps,
    Interviews,
  },
  data() {
    return {
      id_com_brand: null,
      sdate: null,
      edate: null,
      loading: null,
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    sales() {
      const data = this.$store.getters['saleUsers/getSales']
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
    downloadExcel() {
      const urlParams2 = []
      if (this.sdate) {
        urlParams2.push(`sdate=${this.sdate}`)
      }
      if (this.edate) {
        urlParams2.push(`edate=${this.edate}`)
      }
      if (this.id_com_brand) {
        urlParams2.push(`id_com_brand=${this.id_com_brand}`)
      }
      const params = urlParams2.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/sales/sale_users/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    downloadPDF() {
      const urlParams2 = []
      if (this.sdate) {
        urlParams2.push(`sdate=${this.sdate}`)
      }
      if (this.edate) {
        urlParams2.push(`edate=${this.edate}`)
      }
      if (this.id_com_brand) {
        urlParams2.push(`id_com_brand=${this.id_com_brand}`)
      }
      const params = urlParams2.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/sales/sale_users/pdf?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  created() {
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: { salestatus: 1 },
      })
    },
    getSales() {
      if (this.sdate && this.edate) {
        this.loading = true
        this.$store.dispatch('saleUsers/sales', {
          id_com_brand: this.id_com_brand,
          sdate: this.sdate,
          edate: this.edate,
        })
      }
    },
  },
}
</script>

<style scoped></style>
