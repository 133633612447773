<template>
  <div>
    <Loading v-if="loading" />
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Perakende Özet
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="summary"
        :items="sales.summary"
      >
        <template #cell(swap_percent)="data">
          %{{ data.item.swap_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.swaps }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryTotal.swap_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Temsilciler
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="users"
        :items="sales.users"
      >
        <template #cell(swap_percent)="data">
          %{{ data.item.swap_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th colspan="2">
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.swaps }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.usersTotal.swap_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Havuz
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="pools"
        :items="sales.pools"
      >
        <template #cell(swap_percent)="data">
          %{{ data.item.swap_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.swaps }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.poolsTotal.swap_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
  </div>
</template>
<script>
import {
  BTable, BTh, BTr,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'

export default {
  name: 'Sales',
  components: {
    BTable,
    BTr,
    BTh,
    Loading,
  },
  data() {
    return {
      loading: null,
      summary: [
        {
          key: 'brand', label: 'Marka', sortable: true,
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center width-250', tdClass: 'text-center',
        },
        {
          key: 'swaps', label: 'Takas', sortable: true, thClass: 'text-center width-250', tdClass: 'text-center',
        },
        {
          key: 'swap_percent', label: 'Takas Oranı', sortable: true, thClass: 'text-center width-250', tdClass: 'text-center',
        },
      ],
      users: [
        {
          key: 'username', label: 'Danışman', sortable: true, thClass: 'width-250',
        },
        {
          key: 'brand', label: 'Marka', sortable: true,
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center width-250', tdClass: 'text-center',
        },
        {
          key: 'swaps', label: 'Takas', sortable: true, thClass: 'text-center width-250', tdClass: 'text-center',
        },
        {
          key: 'swap_percent', label: 'Takas Oranı', sortable: true, thClass: 'text-center width-250', tdClass: 'text-center',
        },
      ],
      pools: [
        {
          key: 'brand', label: 'Marka', sortable: true,
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center width-250', tdClass: 'text-center',
        },
        {
          key: 'swaps', label: 'Takas', sortable: true, thClass: 'text-center width-250', tdClass: 'text-center',
        },
        {
          key: 'swap_percent', label: 'Takas Oranı', sortable: true, thClass: 'text-center width-250', tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    sales() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = true
      const data = this.$store.getters['saleUsers/getSales']
      if (data.users.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
  },
}
</script>
