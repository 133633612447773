<template>
  <div>
    <Loading v-if="loading" />
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Temsilciler
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="users"
        :items="sales.interviews"
      >
        <template #cell(web_percent)="data">
          %{{ data.item.web_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th colspan="2">
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.interviewsTotal.phone }}
            </b-th>
            <b-th class="text-center">
              {{ sales.interviewsTotal.showroom }}
            </b-th>
            <b-th class="text-center">
              {{ sales.interviewsTotal.web_dist }}
            </b-th>
            <b-th class="text-center">
              {{ sales.interviewsTotal.web }}
            </b-th>
            <b-th class="text-center">
              {{ sales.interviewsTotal.web_goal }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.interviewsTotal.web_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
  </div>
</template>
<script>
import {
  BTable, BTh, BTr,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'

export default {
  name: 'Sales',
  components: {
    BTable,
    BTr,
    BTh,
    Loading,
  },
  data() {
    return {
      loading: null,
      users: [
        {
          key: 'username', label: 'Danışman', sortable: true, thClass: 'align-middle',
        },
        {
          key: 'brand', label: 'Marka', sortable: true, thClass: 'align-middle',
        },
        {
          key: 'phone', label: 'Telefon', sortable: true, thClass: 'align-middle text-center', tdClass: 'text-center',
        },
        {
          key: 'showroom', label: 'Showroom', sortable: true, thClass: 'align-middle text-center', tdClass: 'text-center',
        },
        {
          key: 'web_dist', label: 'Web Distribütör', sortable: true, thClass: 'align-middle text-center', tdClass: 'text-center',
        },
        {
          key: 'web', label: 'Web', sortable: true, thClass: 'align-middle text-center', tdClass: 'text-center',
        },
        {
          key: 'web_goal', label: 'Web Hedef', sortable: true, thClass: 'align-middle text-center', tdClass: 'text-center',
        },
        {
          key: 'web_percent', label: 'Web Oranı', sortable: true, thClass: 'align-middle text-center', tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    sales() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = true
      const data = this.$store.getters['saleUsers/getSales']
      if (data.users.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
  },
}
</script>
