<template>
  <div>
    <Loading v-if="loading" />
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Genel Toplam
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="summaryAll"
        :items="sales.summaryAll"
      >
        <template #cell(sale_percent)="data">
          %{{ data.item.sale_percent }}
        </template>
        <template #cell(swap_percent)="data">
          %{{ data.item.swap_percent }}
        </template>
        <template #cell(accessories)="data">
          {{ data.item.accessories | toCurrency }} TL
        </template>
        <template #cell(accessory_unit)="data">
          {{ data.item.accessory_unit | toCurrency }} TL
        </template>
        <template #cell(trafik_percent)="data">
          %{{ data.item.trafik_percent }}
        </template>
        <template #cell(kasko_percent)="data">
          %{{ data.item.kasko_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryAllTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryAllTotal.goals }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryAllTotal.sale_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryAllTotal.swap_percent }}
            </b-th>
            <!--            <b-th class="text-right">-->
            <!--              {{ sales.summaryAllTotal.accessories | toCurrency }} TL-->
            <!--            </b-th>-->
            <!--            <b-th class="text-right">-->
            <!--              {{ sales.summaryAllTotal.accessory_unit | toCurrency }} TL-->
            <!--            </b-th>-->
            <b-th class="text-center">
              %{{ sales.summaryAllTotal.trafik_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryAllTotal.kasko_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Perakende Özet
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="summary"
        :items="sales.summary"
      >
        <template #cell(sale_percent)="data">
          %{{ data.item.sale_percent }}
        </template>
        <template #cell(accessories)="data">
          {{ data.item.accessories | toCurrency }} TL
        </template>
        <template #cell(accessory_unit)="data">
          {{ data.item.accessory_unit | toCurrency }} TL
        </template>
        <template #cell(trafik_percent)="data">
          %{{ data.item.trafik_percent }}
        </template>
        <template #cell(kasko_percent)="data">
          %{{ data.item.kasko_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.summaryTotal.goals }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryTotal.sale_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryTotal.swap_percent }}
            </b-th>
            <!--            <b-th class="text-right">-->
            <!--              {{ sales.summaryTotal.accessories | toCurrency }} TL-->
            <!--            </b-th>-->
            <!--            <b-th class="text-right">-->
            <!--              {{ sales.summaryTotal.accessory_unit | toCurrency }} TL-->
            <!--            </b-th>-->
            <b-th class="text-center">
              %{{ sales.summaryTotal.trafik_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.summaryTotal.kasko_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Havuz
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="pools"
        :items="sales.pools"
      >
        <template #cell(sale_percent)="data">
          %{{ data.item.sale_percent }}
        </template>
        <template #cell(swap_percent)="data">
          %{{ data.item.swap_percent }}
        </template>
        <template #cell(accessories)="data">
          {{ data.item.accessories | toCurrency }} TL
        </template>
        <template #cell(accessory_unit)="data">
          {{ data.item.accessory_unit | toCurrency }} TL
        </template>
        <template #cell(trafik_percent)="data">
          %{{ data.item.trafik_percent }}
        </template>
        <template #cell(kasko_percent)="data">
          %{{ data.item.kasko_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.poolsTotal.goals }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.poolsTotal.sale_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.poolsTotal.swap_percent }}
            </b-th>
            <!--            <b-th class="text-right">-->
            <!--              {{ sales.poolsTotal.accessories | toCurrency }} TL-->
            <!--            </b-th>-->
            <!--            <b-th class="text-right">-->
            <!--              {{ sales.poolsTotal.accessory_unit | toCurrency }} TL-->
            <!--            </b-th>-->
            <b-th class="text-center">
              %{{ sales.poolsTotal.trafik_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.poolsTotal.kasko_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
    <template>
      <div class="mt-2 mb-2 font-medium-4 text-center border-bottom font-weight-bold">
        Temsilciler
      </div>
      <b-table
        striped
        hover
        responsive
        :fields="users"
        :items="sales.users"
      >
        <template #cell(sale_percent)="data">
          %{{ data.item.sale_percent }}
        </template>
        <template #cell(swap_percent)="data">
          %{{ data.item.swap_percent }}
        </template>
        <template #cell(accessories)="data">
          {{ data.item.accessories | toCurrency }} TL
        </template>
        <template #cell(accessory_unit)="data">
          {{ data.item.accessory_unit | toCurrency }} TL
        </template>
        <template #cell(trafik_percent)="data">
          %{{ data.item.trafik_percent }}
        </template>
        <template #cell(kasko_percent)="data">
          %{{ data.item.kasko_percent }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th colspan="2">
              Toplam
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.sales }}
            </b-th>
            <b-th class="text-center">
              {{ sales.usersTotal.goals }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.usersTotal.sale_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.usersTotal.swap_percent }}
            </b-th>
            <!--            <b-th class="text-right">-->
            <!--              {{ sales.usersTotal.accessories | toCurrency }} TL-->
            <!--            </b-th>-->
            <!--            <b-th class="text-right">-->
            <!--              {{ sales.usersTotal.accessory_unit | toCurrency }} TL-->
            <!--            </b-th>-->
            <b-th class="text-center">
              %{{ sales.usersTotal.trafik_percent }}
            </b-th>
            <b-th class="text-center">
              %{{ sales.usersTotal.kasko_percent }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </template>
  </div>
</template>
<script>
import {
  BTh, BTr, BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'

export default {
  name: 'Sales',
  components: {
    BTr,
    BTh,
    BTable,
    Loading,
  },
  data() {
    return {
      loading: null,
      summary: [
        {
          key: 'brand', label: 'Marka', sortable: true,
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'goals', label: 'Hedef', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'sale_percent', label: 'Satış Gerçekleşme', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'swap_percent', label: 'Takas Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        // {
        //   key: 'accessories', label: 'Aksesuar Satışı', sortable: true, thClass: 'text-right', tdClass: 'text-right',
        // },
        // {
        //   key: 'accessory_unit', label: 'Araç Başı Aksesuar', sortable: true, thClass: 'text-right', tdClass: 'text-right',
        // },
        {
          key: 'trafik_percent', label: 'Trafik Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'kasko_percent', label: 'Kasko Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      users: [
        {
          key: 'username', label: 'Danışman', sortable: true,
        },
        {
          key: 'brand', label: 'Marka', sortable: true,
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'goals', label: 'Hedef', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'sale_percent', label: 'Satış Gerçekleşme', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'swap_percent', label: 'Takas Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        // {
        //   key: 'accessories', label: 'Aksesuar Satışı', sortable: true, thClass: 'text-right', tdClass: 'text-right',
        // },
        // {
        //   key: 'accessory_unit', label: 'Araç Başı Aksesuar', sortable: true, thClass: 'text-right', tdClass: 'text-right',
        // },
        {
          key: 'trafik_percent', label: 'Trafik Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'kasko_percent', label: 'Kasko Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      pools: [
        {
          key: 'brand', label: 'Marka', sortable: true,
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'goals', label: 'Hedef', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'sale_percent', label: 'Satış Gerçekleşme', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'swap_percent', label: 'Takas Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        // {
        //   key: 'accessories', label: 'Aksesuar Satışı', sortable: true, thClass: 'text-right', tdClass: 'text-right',
        // },
        // {
        //   key: 'accessory_unit', label: 'Araç Başı Aksesuar', sortable: true, thClass: 'text-right', tdClass: 'text-right',
        // },
        {
          key: 'trafik_percent', label: 'Trafik Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'kasko_percent', label: 'Kasko Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      summaryAll: [
        {
          key: 'brand', label: 'Marka', sortable: true,
        },
        {
          key: 'sales', label: 'Satış', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'goals', label: 'Hedef', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'sale_percent', label: 'Satış Gerçekleşme', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'swap_percent', label: 'Takas Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        // {
        //   key: 'accessories', label: 'Aksesuar Satışı', sortable: true, thClass: 'text-right', tdClass: 'text-right',
        // },
        // {
        //   key: 'accessory_unit', label: 'Araç Başı Aksesuar', sortable: true, thClass: 'text-right', tdClass: 'text-right',
        // },
        {
          key: 'trafik_percent', label: 'Trafik Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'kasko_percent', label: 'Kasko Oranı', sortable: true, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    sales() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = true
      const data = this.$store.getters['saleUsers/getSales']
      if (data.users.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
  },
}
</script>
